(function ($) {
  Drupal.behaviors.offersBannerFormatterV1 = {
    attach: function (context) {
      var $offerBanners = $('.js-offers-banner-slider', context);
      var basicSlide = '.js-offers-banner-slide__item';

      function changeBgColor(currentSlide, $offerBanner) {
        var $currentBanner = $(
          '.js-offers-banner-slide__item[data-slick-index=' + currentSlide + ']',
          $offerBanner
        );
        var bgColor = $currentBanner.find('.js-offers-banner').data('backgroundColor') || '';

        $offerBanner.css('background-color', bgColor);
      }

      $offerBanners.each(function () {
        var $offerBanner = $(this);
        var $offerBannerItem = $('.js-offers-banner-slide__item', $offerBanner);
        // Autoplay settings
        var autoplay = parseInt($offerBanner.attr('data-slides-autoplay'), 10) ? true : false;
        // Display arrow settings
        var displayArrows = parseInt($offerBanner.attr('data-arrows'), 10) ? true : false;
        var settings = {
          arrows: displayArrows,
          slide: basicSlide,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: autoplay,
          autoplaySpeed: 5000,
          adaptiveHeight: false,
          fade: true
        };

        if ($offerBannerItem.length < 1 || $offerBanner.hasClass('slick-initialized')) {
          return;
        }

        $offerBanner.on('init', function (e, slick) {
          var $banner = $(this);
          var $slickArrows = $banner.find('button.slick-arrow');
          var arrowsColor = $offerBanner.attr('data-arrows-color');
          var styleAttr = $banner.attr('style') || '';

          $slickArrows.wrap('<div class="carousel-controls"></div>');

          if (arrowsColor) {
            $banner.attr('style', `${styleAttr}; --offer-banner-arrow-color: ${arrowsColor}`);
          }

          changeBgColor(slick.currentSlide, $banner);
        });
        $offerBanner.on('beforeChange', function (e, slick, currentSlide, nextSlide) {
          changeBgColor(nextSlide, $(this));
        });
        $offerBanner.not('.slick-initialized').slick(settings);
      });
    }
  };
})(jQuery);
